import React, { Component } from "react";
import { View, Text, ActivityIndicator, Image } from "react-native";
// import TrackVisitor from "./TrackVisitor";
// import TrackVisitorAndIP from "./TrackVisitorAndIP";
import axios from "axios";
import { api_qrscanned, api_generalqrscanned } from "./Api";

class RedirectTracker extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			brand: "",
			urls: [
				{
					id: "",
					redirectUrl: "https://www.revlon.co.za/face#e=0",
					allocation: "VTO - REVLON",
					brand: "REVLON",
				},
				{
					id: "vto",
					redirectUrl: "https://tryonnow.revlon.co.za/",
					allocation: "REVLON - VTO",
					brand: "REVLON",
				},
				{
					id: "vtocolorsilk",
					redirectUrl:
						"https://www.revlon.co.za/hair/permanent-hair-color/colorsilk-beautiful-color-hair-color?shade=black&vto=tryon",
					allocation: "REVLON - VTO - ColorSilk",
					brand: "REVLON",
				},
				{
					id: "cshairvto2",
					redirectUrl: "https://tryonnow.revlon.co.za/",
					allocation: "REVLON - VTO",
					brand: "REVLON",
				},
				{
					id: "wteaufloraleps",
					redirectUrl: "https://wteauflorale.skintools.co.za",
					allocation: "WHITE TEA EAU FLORALE",
					brand: "Elizabeth Arden",
				},
				// {
				// 	id: "wteaufloraleps",
				// 	redirectUrl: "https://elizabetharden.co.za",
				// 	allocation: "WHITE TEA EAU FLORALE",
				// 	brand: "Elizabeth Arden",
				// },
				{
					id: "cppp24",
					redirectUrl: "https://alc.skintools.co.za",
					allocation: "Ceramide QR Tracker",
					brand: "Elizabeth Arden",
				},
			],
		};
	}

	async componentDidMount() {
		this.trackInteraction();
	}

	trackInteraction = async () => {
		// const data = await this.TrackVisitorAndIP();
		const ip = await this.geoAppify();

		let url = this.state.urls.find((d) => d.id === this.props.id);

		// return;
		console.log("geoAppify", ip, url);
		if (typeof url === "undefined") {
			window.location.href = "https://tryonnow.revlon.co.za/";
		} else {
			this.setState({
				brand: url.brand,
			});
			await fetch(api_generalqrscanned, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					allocation: url.allocation,
					// ...data,
					...ip,
					...url,
					...this.props,
				}),
			})
				.then((response) => response.json())
				.then((data) => {
					this.setState({
						redirectUrl: url.redirectUrl,
						isLoading: false,
					});
					window.location.href = url.redirectUrl;
					return null;
				})
				.catch((error) => {
					window.location.href = "https://tryonnow.revlon.co.za/";
					console.error(error);
					this.setState({ isLoading: false });
				});
		}
	};

	geoAppify = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			console.log("TrackVisitorAndIP analyticsData", analyticsData);

			return analyticsData;
		} catch (error) {
			return error;
		}
	};

	TrackVisitorAndIP = async () => {
		try {
			const {
				data: { ip },
			} = await axios.get("https://api.ipify.org/?format=json"); // get the user's IP address

			const { data } = await axios.get(
				`https://ipinfo.io/${ip}/json?token=YOUR_TOKEN_HERE`,
			); // get the user's geolocation data

			const analyticsData = {
				ipAddress: data.ip,
				city: data.city,
				region: data.region,
				country: data.country,
				lat: data.loc.split(",")[0],
				lon: data.loc.split(",")[1],
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			console.log("TrackVisitorAndIP analyticsData", analyticsData);

			return analyticsData;
		} catch (error) {
			return error;
		}
	};

	TrackVisitor = () => {
		axios
			.get("https://api.ipify.org/?format=json") // get the user's IP address
			.then((response) => {
				const ipAddress = response.data.ip;
				const userAgent = window.navigator.userAgent;
				const screenWidth = window.screen.width;
				const screenHeight = window.screen.height;

				console.log(ipAddress, userAgent, screenWidth, screenHeight);

				return {
					ipAddress,
					userAgent,
					screenWidth,
					screenHeight,
				};
			})
			.catch((error) => {
				return error;
			});
	};

	render() {
		return (
			<View
				style={{
					// flex: 1,
					justifyContent: "center",
					alignItems: "center",
					width: window.innerWidth,
					height: window.innerHeight,
				}}>
				<View
					style={{
						position: "absolute",
						alignItems: "center",
					}}>
					{this.state.brand === "REVLON" ? (
						<Image
							source={require("./assets/revlon-logo.png")}
							style={{
								width: 200,
								height: 90,
								resizeMode: "contain",
							}}
						/>
					) : null}
					{this.state.brand === "Elizabeth Arden" ? (
						<Image
							source={{
								uri: "https://www.elizabetharden.co.za/images/sitewide/EA-Logo-2020-320x59.svg",
							}}
							style={{
								width: 200,
								height: 90,
								resizeMode: "contain",
							}}
						/>
					) : null}

					<ActivityIndicator size='small' color='#000' />
				</View>
			</View>
		);
	}
}

export default RedirectTracker;
