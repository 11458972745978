// import React, { Component } from "react";
// import { useParams, useLocation } from "react-router-dom";
// import RedirectTracker from "./RedirectTracker";

// function useQuery() {
// 	// WORKS FOR QURY URL
// 	const { search } = useLocation();
// 	return React.useMemo(() => new URLSearchParams(search), [search]);
// }

// export default async function VerifyUrl() {
// 	let { id } = useParams();
// 	let query = useQuery();
// 	let e = query.get("id");
// 	let t = query.get("t");
// 	// await console.log(id, email); // WORKS FOR QURY URL
// 	// return;
// 	console.log(e);
// 	// await localStorage.setItem("rt_", t);
// 	// await localStorage.setItem("e", e);
// 	// window.location.href = "/ResetPassword";
// 	// return id;

// 	console.log(t);
// 	// return <VideoPlayer width={window.innerWidth} videos={videos} />;

// 	return <RedirectTracker id={e} token={t} />;
// }

import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import RedirectTracker from "./RedirectTracker";

function useQuery() {
	const { search } = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function VerifyUrl() {
	// const { id } = useParams();
	const query = useQuery();
	const e = query.get("id");
	const region = query.get("region");
	const retailer = query.get("retailer");
	const branch = query.get("branch");
	const rdm = query.get("rdm");

	// console.log(e);

	return (
		<RedirectTracker
			id={"vto"}
			dataObject={{
				region,
				retailer,
				branch,
				rdm,
			}}
		/>
	);
}

export default VerifyUrl;
